<template>
  <b-nav-item>
    <b-tooltip
      target="tooltip-target-1"
      triggers="hover"
      no-fade
    >
      Descargar guía mindext
    </b-tooltip>
    <feather-icon
      id="tooltip-target-1"
      size="21"
      icon="DownloadIcon"
      @click="DownloadGuia"
    />
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem, BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
    BTooltip,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
  methods: {
    DownloadGuia() {
      window.open(`${process.env.VUE_APP_URL_API}/GUIA-DEL-ESTUDIANTE-MINDEXT-2018.pdf`)
    },
  },
}
</script>
